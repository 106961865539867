

import { mountComponent } from '@yggdrasil/Order/AddToCart/addToCart'
import { CF2Component } from 'javascript/lander/runtime'

export default class AddToCart extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}



  mount() {
    mountComponent(this)
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const product = this.product ?? null ;
const currentVariant = this.currentVariant ?? null ;
const selected_price = this.selected_price ?? null ;
const button_layout_size = this.button_layout_size ?? null ;
const input_layout_size = this.input_layout_size ?? null ;

    const editor_product = this.element.getAttribute('data-param-editor_product');
const primary_text = this.element.getAttribute('data-param-primary_text');
const secondary_text = this.element.getAttribute('data-param-secondary_text');
  
      let html = '';
      {
        const is_editor = false;
        const customVariants = product.variants.filter((value) => value["default_variant"] == false);
        const variant = currentVariant ?? product;
        const selected_price = variant.prices[0];
        const desc = (product.description ?? product.description_override ?? variant.description ?? product.default_variant.description ?? "").replaceAll(`
`,"<br>");
        html += `<span class="elAddToCartName">${variant.name ?? product.default_variant.name}</span>`
        if (variant.prices.length == 1) {
          html += `<div class="elAddToCartSinglePriceWrapper"><span class="elAddToCartPrice">${selected_price.name}</span>`
          const compare_at_amount = selected_price.compare_at_amount;
          if (compare_at_amount) {
            html += `<span class="elAddToCartCompareAt">${compare_at_amount}</span>`
          }
          html += `</div>`
        } else {
          html += `<div class="elSelectWrapper`
          if (input_layout_size == "xs") {
            html += ` elAddToCartInputExtraSmall`
          }
          if (input_layout_size == "s") {
            html += ` elAddToCartInputSmall`
          }
          if (input_layout_size == "m") {
            html += ` elAddToCartInputMedium`
          }
          if (input_layout_size == "l") {
            html += ` elAddToCartInputLarge`
          }
          if (input_layout_size == "xl") {
            html += ` elAddToCartInputExtraLarge`
          }
          html += `"><select name="variant_price">`
          const c0 = variant.prices
          const fl1 = new CF2ForloopDrop(c0.length)
          for (const price of c0) {
            const forloop = fl1
            html += `<option `
            if (selected_price.id == price.id) {
              html += `selected`
            }
            html += ` value="${price.id}">${price.name}</option>`
            forloop.next();
          }
          html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
        }
        if (desc != "") {
          html += `<span class="elAddToCartDescription">${desc}</span>`
        }
        if (customVariants.length > 1) {
          html += `<div class="elAddToCartVariantSelectorWrapper">`
          const c2 = product.sorted_property_values
          const fl3 = new CF2ForloopDrop(c2.length)
          for (const property_values of c2) {
            const forloop = fl3
            const property_id = property_values.property_id;
            const select_index = forloop.index0;
            html += `<div class="elSelectWrapper`
            if (input_layout_size == "xs") {
              html += ` elAddToCartInputExtraSmall`
            }
            if (input_layout_size == "s") {
              html += ` elAddToCartInputSmall`
            }
            if (input_layout_size == "m") {
              html += ` elAddToCartInputMedium`
            }
            if (input_layout_size == "l") {
              html += ` elAddToCartInputLarge`
            }
            if (input_layout_size == "xl") {
              html += ` elAddToCartInputExtraLarge`
            }
            html += `"><span class="elAddToCartVariantSelectorTitle">${product.all_properties[property_id]}</span><div style="position:relative;"><select class="elVariantSelector" name="property_${property_id}">`
            const c4 = property_values.value_ids
            const fl5 = new CF2ForloopDrop(c4.length)
            for (const value_id of c4) {
              const forloop = fl5
              html += `<option `
              if (variant.property_value_ids[select_index] == value_id) {
                html += `selected`
              }
              html += ` value="${value_id}">${product.all_properties_values[value_id]}</option>`
              forloop.next();
            }
            html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
            forloop.next();
          }
          html += `</div>`
        }
        html += `<div class="elAddToCartButtonsWrapper"><div data-page-element="Button/V1" class="elBTN elAddToCartAddToCartButton id-Button/V1`
        if (button_layout_size == "xs") {
          html += ` elAddToCartButtonExtraSmall`
        }
        if (button_layout_size == "s") {
          html += ` elAddToCartButtonSmall`
        }
        if (button_layout_size == "m") {
          html += ` elAddToCartButtonMedium`
        }
        if (button_layout_size == "l") {
          html += ` elAddToCartButtonLarge`
        }
        if (button_layout_size == "xl") {
          html += ` elAddToCartButtonExtraLarge`
        }
        html += `" data-liquid-replace="item"><a target="_self" href="#add-to-cart" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">${primary_text}</span></span><span class="elButtonSub"></span></a></div><div data-page-element="Button/V1" class="elBTN elAddToCartBuyNowButton id-Button/V1`
        if (button_layout_size == "xs") {
          html += ` elAddToCartButtonExtraSmall`
        }
        if (button_layout_size == "s") {
          html += ` elAddToCartButtonSmall`
        }
        if (button_layout_size == "m") {
          html += ` elAddToCartButtonMedium`
        }
        if (button_layout_size == "l") {
          html += ` elAddToCartButtonLarge`
        }
        if (button_layout_size == "xl") {
          html += ` elAddToCartButtonExtraLarge`
        }
        html += `" data-liquid-replace="item"><a target="_self" href="#buy-now" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">${secondary_text}</span></span><span class="elButtonSub"></span></a></div></div>`

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

window["AddToCart"] = AddToCart

